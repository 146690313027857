// TODO redefine this layout file.

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import "./layout.css"
import Nav from "./components/Nav/Nav.js"
//import Header from "./Navigation/Header"
//import Footer from "./Navigation/Footer"

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <Nav />

      <Wrapper>
        <BodyContainer>
          <main>{children}</main>
        </BodyContainer>
      </Wrapper>
      {/* <Footer /> */}
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
  }
`

const BodyContainer = styled.div`
  min-height: 80vh;
  background: #f2f2f2;
  width: 100%;
  //font-family: var(--main-font), sans-serif;
  h1 {
    font-family: var(--main-font);
  }

  h2 {
    font-family: var(--main-font);
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
