import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavButton = () => {
  return (
    <Button>
      <OneLink to="/hire">Contact me</OneLink>
    </Button>
  )
}

const Button = styled.div`
  background: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 1vh;
`

const OneLink = styled(Link)`
  padding: 8px;
  color: white;
  text-decoration: none;
  font-weight: bolder;
`

export default NavButton
