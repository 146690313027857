import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import NavButton from "./NavButton"

const Nav = () => {
  return (
    <Container>
      <NavRight>
        <Linky to="/">Home</Linky>
        <Linky to="/about">About</Linky>
        <Linky to="/portfolio">Work</Linky>
        <NavButton />
      </NavRight>
    </Container>
  )
}

const Container = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: right;
  background: #f2f2f2;
  height: 10vh;
  z-index: 10001;
`

const NavRight = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  //background: yellow;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Linky = styled(Link)`
  text-decoration: none;
  color: black;
`

export default Nav
